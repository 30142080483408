import { configureStore } from "@reduxjs/toolkit";
import { configuratorSlice } from "@/features/configuratorSlice";
import { configuratorToolsSlice } from "@/features/configuratorToolsSlice";
import { frameloopSlice } from "@/features/frameloopSlice";
import { fontsSlice } from "@/features/fontsSlice";
import { diffuseMapsSlice } from "@/features/diffuseMapsSlice";
import { configurator3DSlice } from "@/features/configurator3DSlice";
import { configurator3DToolsSlice } from "@/features/configurator3DToolsSlice";
import { loaderSlice } from "@/features/loaderSlice";
import { themeSlice } from "@/features/themeSlice";
import { projectSlice } from "@/features/projectSlice";

const store = configureStore({
  reducer: {
    [configuratorSlice.name]: configuratorSlice.reducer,
    [configuratorToolsSlice.name]: configuratorToolsSlice.reducer,
    [frameloopSlice.name]: frameloopSlice.reducer,
    [fontsSlice.name]: fontsSlice.reducer,
    [diffuseMapsSlice.name]: diffuseMapsSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [configurator3DSlice.name]: configurator3DSlice.reducer,
    [configurator3DToolsSlice.name]: configurator3DToolsSlice.reducer,
    [loaderSlice.name]: loaderSlice.reducer,
    [themeSlice.name]: themeSlice.reducer,
  },
  devTools: true,
});

export { store };
