import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  customFonts: [],
  googleFonts: [],
};

// Actual Slice
export const fontsSlice = createSlice({
  name: "fonts",
  initialState,
  reducers: {
    setCustomFonts(state, action) {
      state.customFonts.push(action.payload);
    },
    setGoogleFonts(state, action) {
      state.googleFonts.push(action.payload);
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.fonts,
      };
    },
  },
});

export const { setCustomFonts, setGoogleFonts } = fontsSlice.actions;

export default fontsSlice.reducer;
